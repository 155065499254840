body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(234, 227, 209, 1));
  background-size: contain;
  background-position: center;
  user-select: none;
}



